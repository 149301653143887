import api_request from "../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class ContentTypeBuilder {
  api_request = new api_request("/api/service");

  async createPage(
    name,
    description,
    contentType,
    createdBy,
    createdAt,
    slug,
    parentPage
  ) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "createPage";
      magicJson.data = [
        {
          name: name,
          description: description,
          contentType: contentType,
          parentPage,
          createdBy: createdBy,
          createdAt: createdAt,
          modifiedBy: createdBy,
          modifiedAt: createdAt,
          slug,
        },
      ];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async createPageWithJson(json) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "createPageWithJson";
      magicJson.data = [json];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async createComponent(name, global, description, createdBy, createdAt, slug) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "createComponent";
      magicJson.data = [
        {
          name: name,
          global: global,
          description: description,
          createdBy: createdBy,
          createdAt: createdAt,
          modifiedBy: createdBy,
          modifiedAt: createdAt,
          slug,
          componentData: [],
        },
      ];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async updateComponent(
    id,
    name,
    global,
    description,
    modifiedBy,
    modifiedAt,
    componentData,
    seoMetaData = {},
    seoTagsData = {},
    imageData = [],
    cronExpirationData,
    status
  ) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "updateComponent";
      magicJson.data = [
        {
          id,
          name,
          global,
          description,
          modifiedBy,
          modifiedAt,
          componentData,
          seoMetaData,
          seoTagsData,
          cronExpirationData,
          status
        },
      ];
      const result = await this.api_request.sendRequest(magicJson, imageData);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async updatePage(
    id,
    parentPage,
    name,
    description,
    slug,
    modifiedBy,
    modifiedAt,
    componentData,
    seoMetaData = {},
    seoTagsData = {},
    imageData = [],
    compRecord,
    collectionRecord,
    contentType,
    cronExpirationData = [],
    status=""
  ) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "updatePage";
      magicJson.data = [
        {
          id,
          name,
          description,
          modifiedBy,
          modifiedAt,
          componentData,
          seoMetaData,
          seoTagsData,
          imageData,
          parentPage,
          slug,
          compRecord,
          collectionRecord,
          contentType,
          cronExpirationData,
          status
        },
      ];
      const result = await this.api_request.sendRequest(magicJson, imageData);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }
  async draftsave(id, data, images) {
    try {
      console.log(images)
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "draftPage";
      magicJson.data = data;
      magicJson.metadata.data.filter = { pageid: id };
      const result = await this.api_request.sendRequest(magicJson, images);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async updateDraft(data, files) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "updateDraft";
      magicJson.data = data;
      const result = await this.api_request.sendRequest(magicJson, files);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async getDraftList(parentId, status) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "getDraftList";
      magicJson.data = { parentId, status };
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async deleteDraft(id) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "deleteDraft";
      magicJson.data = { id };
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async updateCollection(id, componentData, imageData = []) {
    magicJson.endpoint = "contentManager";
    magicJson.executor = "collectionOrderChange";
    magicJson.data = [{ id, componentData }];
    const result = await this.api_request.sendRequest(magicJson, imageData);
    return result.data[0];
  }

  async getPages(conditions = {}) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "getPages";
      magicJson.filter = conditions;
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async getComponents(conditions = {}) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "getComponents";
      magicJson.filter = conditions;
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async deletePage(id, deleteFromEverywhere) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "deletePage";
      magicJson.data = [{ id, deleteFromEverywhere }];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async deleteComponent(id, deleteFromEverywhere) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "deleteComponent";
      magicJson.data = [{ id, deleteFromEverywhere }];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async deleteAndKeepComponent(id) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "deleteAndKeepComponent";
      magicJson.data = [{ id }];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      console.log(error);
    }
  }

  async deleteAndKeepCollection(id) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "deleteAndKeepCollection";
      magicJson.data = [{ id }];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      console.log(error);
    }
  }

  async getUpToDatePage(obj) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      (magicJson.executor = "getUpToDatePage"), (magicJson.data = [obj]);

      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async getDraftData(id) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "getDraftData";
      magicJson.data = [{ id }];
      const result = await this.api_request.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  }

  async unPublishPage(id, data) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "unPublishPage";
      magicJson.data = [{ componentData : data, "id": id }];

      const result = await this.api_request.sendRequest(magicJson);
      return result;
    } catch(error) {
      return error;
    }
  }

  async createComponentVersion(id, data, images) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "draftComponent";
      magicJson.data = data;

      const result = await this.api_request.sendRequest(magicJson, images);

      return result;
    } catch(error) {
      return error;
    }
  }

  async getComponentsDraftList(parentId, status) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "getComponentsDraftList";
      magicJson.data = { parentId, status };
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async getComponentsDraftData(id) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "getComponentsDraftData";
      magicJson.data = [{ id }];
      const result = await this.api_request.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  }

  async updateComponentVersion(data, files) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "updateComponentDraft";
      magicJson.data = data;
      const result = await this.api_request.sendRequest(magicJson, files);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async deleteComponentVersion(id) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "deleteComponentVersion";
      magicJson.data = { id };
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async unPublishComponent(id, data) {
    try {
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "unPublishComponent";
      magicJson.data = [{ componentData : data, "id": id }];

      const result = await this.api_request.sendRequest(magicJson);
      return result;
    } catch(error) {
      return error;
    }
  }
}

export default ContentTypeBuilder;
