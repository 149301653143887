import magicJson from "./../../../magicJson";
import api_request from "../../../libs/sendRequest/sendRequest";

class MediaLibrary {
  api_request = new api_request("/api/service");

  getFolders = async () => {
    try {
      magicJson.endpoint = "mediaLibrary";
      magicJson.executor = "getFolders";
      magicJson.data = [];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  };

  createFolder = async(folder_name,parent_id=null)=>{
    try {
      magicJson.endpoint = "mediaLibrary";
      magicJson.executor = "createFolder";
      magicJson.data = [{
        folder_name: folder_name,
        isRoot: parent_id==null,
        parent_id:parent_id,
        user:JSON.parse(localStorage.getItem("user")).userName
      }]
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error
    }
  }

  uploadAssets = async(folder_id,file)=>{

    if(typeof folder_id == 'object'){
      let plainObject = JSON.parse(JSON.stringify(folder_id));
      folder_id = plainObject.location;
      let file_id = plainObject.id;
      let file_name = plainObject.fileName;
      let keyword = plainObject.keywords;
      let desc = plainObject.desc;
      let title = plainObject.title;
      try {
        magicJson.endpoint="mediaLibrary";
        magicJson.executor = "uploadFile"
        magicJson.data = [{
          folder_id: folder_id,
          file_id: file_id,
          file_name: file_name,
          keyword : keyword,
          desc: desc,
          title: title,
        }]
        const result = await this.api_request.sendRequest(magicJson,file)
        
        return result.data[0]
      } catch (error) {
        return error;
      }
      
    }
    else{

      try {
        magicJson.endpoint="mediaLibrary";
        magicJson.executor = "uploadFile"
        magicJson.data = [{
          folder_id: folder_id,
        }]
        const result = await this.api_request.sendRequest(magicJson,file)
        
        return result.data[0]
      } catch (error) {
        return error;
      }
    }

  }

  reteriveFolder = async(folder_id)=>{
    try {
      magicJson.endpoint="mediaLibrary";
      magicJson.executor = "reteriveFolder"
      magicJson.data = [{
        folder_id: folder_id,
      }]
      const result = await this.api_request.sendRequest(magicJson)
      return result.data[0]
    } catch (error) {
      return error
    }
  }

  deleteFilesAndFolders = async(folders,files)=>{
    try {
      magicJson.endpoint="mediaLibrary";
      magicJson.executor = "deleteFolder"
      magicJson.data = [{
        folders: Array.from(folders),
        files:Array.from(files)
      }]
      const result = await this.api_request.sendRequest(magicJson)
      return result.data[0]
    } catch (error) {
      return error
    }
  }

  editFolderName = async(folder_id,foldername)=>{
    try {
      magicJson.endpoint="mediaLibrary";
      magicJson.executor="editFolder"
      magicJson.data = [{
        folder_id:folder_id,
        folder_name: foldername,
      }]
      const result = await this.api_request.sendRequest(magicJson)
      return result.data[0]
    } catch (error) {
      return error
    }
  }

  editFile = async(folder_id, file_id, file_name, keyword, desc, title, file,type)=>{
    try {
      magicJson.endpoint="mediaLibrary";
      magicJson.executor="editFileData"
      magicJson.data = [{
        folder_id:folder_id,
        file_id: file_id,
        file_name: file_name,
        keywords: keyword,
        desc: desc,
        title:title,
        type:type
      }]
      const result = await this.api_request.sendRequest(magicJson, file);
      return result.data[0]
    } catch (error) {
      return error
    }
  }
}


export default MediaLibrary
